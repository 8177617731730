// import dependencies
import React from "react";

// import styles and assets
import * as styles from "../styles/card-post.module.scss";

export default function CardPost({children}) {
    return (
        <div className={styles.cardPost}>
            {children}
        </div>
    );
}
