// extracted by mini-css-extract-plugin
export const sectionSpacing = "pagination-module--section-spacing--N6pzS";
export const rowSpacing = "pagination-module--row-spacing--3ZqOZ";
export const rowSpacingSml = "pagination-module--row-spacing-sml--cqIjN";
export const pagination = "pagination-module--pagination--2jp3T";
export const yellow = "pagination-module--yellow--3Q6wo";
export const orange = "pagination-module--orange--8jWyS";
export const turquoise = "pagination-module--turquoise--4UMdN";
export const red = "pagination-module--red--uzayN";
export const green = "pagination-module--green--V4Ey6";
export const white = "pagination-module--white--2Ea_s";
export const dark = "pagination-module--dark--31fty";