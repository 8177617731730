// import dependencies
import React from "react";
import {Link} from "gatsby";

// import styles and assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';
import * as styles from "./styles/pagination.module.scss";

export default function Pagination({pageContext, color}) {
    const {
        previousPagePath,
        nextPagePath,
        humanPageNumber,
        numberOfPages,
    } = pageContext;

    switch (color) {
        case "yellow":
            return (
                <div className={`col-12 ${styles.pagination} ${styles.yellow}`}>
                    {previousPagePath ? (
                        <Link to={previousPagePath} className="pager__button">
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Página anterior
                        </Link>
                    ) : null}
                    {numberOfPages > 1 && (
                        <div className="pager__location">
                            <p>Página {humanPageNumber} de {numberOfPages}</p>
                        </div>
                    )}
                    {nextPagePath && (
                        <Link to={nextPagePath} className="pager__button">
                            Página siguiente <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                        </Link>
                    )}
                </div>
            );
        case "orange":
            return (
                <div className={`col-12 ${styles.pagination} ${styles.orange}`}>
                    {previousPagePath ? (
                        <Link to={previousPagePath} className="pager__button">
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Página anterior
                        </Link>
                    ) : null}
                    {numberOfPages > 1 && (
                        <div className="pager__location">
                            <p>Página {humanPageNumber} de {numberOfPages}</p>
                        </div>
                    )}
                    {nextPagePath && (
                        <Link to={nextPagePath} className="pager__button">
                            Página siguiente <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                        </Link>
                    )}
                </div>
            );
        case "turquoise":
            return (
                <div className={`col-12 ${styles.pagination} ${styles.turquoise}`}>
                    {previousPagePath ? (
                        <Link to={previousPagePath} className="pager__button">
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Página anterior
                        </Link>
                    ) : null}
                    {numberOfPages > 1 && (
                        <div className="pager__location">
                            <p>Página {humanPageNumber} de {numberOfPages}</p>
                        </div>
                    )}
                    {nextPagePath && (
                        <Link to={nextPagePath} className="pager__button">
                            Página siguiente <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                        </Link>
                    )}
                </div>
            );
        case "red":
            return (
                <div className={`col-12 ${styles.pagination} ${styles.red}`}>
                    {previousPagePath ? (
                        <Link to={previousPagePath} className="pager__button">
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Página anterior
                        </Link>
                    ) : null}
                    {numberOfPages > 1 && (
                        <div className="pager__location">
                            <p>Página {humanPageNumber} de {numberOfPages}</p>
                        </div>
                    )}
                    {nextPagePath && (
                        <Link to={nextPagePath} className="pager__button">
                            Página siguiente <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                        </Link>
                    )}
                </div>
            );
        case "green":
            return (
                <div className={`col-12 ${styles.pagination} ${styles.green}`}>
                    {previousPagePath ? (
                        <Link to={previousPagePath} className="pager__button">
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Página anterior
                        </Link>
                    ) : null}
                    {numberOfPages > 1 && (
                        <div className="pager__location">
                            <p>Página {humanPageNumber} de {numberOfPages}</p>
                        </div>
                    )}
                    {nextPagePath && (
                        <Link to={nextPagePath} className="pager__button">
                            Página siguiente <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                        </Link>
                    )}
                </div>
            );
        case "white":
            return (
                <div className={`col-12 ${styles.pagination} ${styles.white}`}>
                    {previousPagePath ? (
                        <Link to={previousPagePath} className="pager__button">
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Página anterior
                        </Link>
                    ) : null}
                    {numberOfPages > 1 && (
                        <div className="pager__location">
                            <p>Página {humanPageNumber} de {numberOfPages}</p>
                        </div>
                    )}
                    {nextPagePath && (
                        <Link to={nextPagePath} className="pager__button">
                            Página siguiente <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                        </Link>
                    )}
                </div>
            );
        case "dark":
            return (
                <div className={`col-12 ${styles.pagination} ${styles.dark}`}>
                    {previousPagePath ? (
                        <Link to={previousPagePath} className="pager__button">
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Página anterior
                        </Link>
                    ) : null}
                    {numberOfPages > 1 && (
                        <div className="pager__location">
                            <p>Página {humanPageNumber} de {numberOfPages}</p>
                        </div>
                    )}
                    {nextPagePath && (
                        <Link to={nextPagePath} className="pager__button">
                            Página siguiente <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                        </Link>
                    )}
                </div>
            );
        default:
            return (
                <div className={`col-12 ${styles.pagination} ${styles.dark}`}>
                    {previousPagePath ? (
                        <Link to={previousPagePath} className="pager__button">
                            <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Página anterior
                        </Link>
                    ) : null}
                    {numberOfPages > 1 && (
                        <div className="pager__location">
                            <p>Página {humanPageNumber} de {numberOfPages}</p>
                        </div>
                    )}
                    {nextPagePath && (
                        <Link to={nextPagePath} className="pager__button">
                            Página siguiente <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                        </Link>
                    )}
                </div>
            );
    }
}